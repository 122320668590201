import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCardText,[_c(VDataTable,{staticStyle:{"cursor":"pointer"},attrs:{"headers":_vm.headers,"items":_vm.documents,"loading":_vm.loader,"loading-text":"Загрузка... Ожидайте!","footer-props":{
            disableItemsPerPage: true,
            itemsPerPageText: ''
          }},scopedSlots:_vm._u([{key:"item.name",fn:function({item}){return [(_vm.docType(item.name) !== 'PDF')?_c('div',{staticStyle:{"background-size":"contain","background-repeat":"no-repeat","height":"200px","width":"300px","border":"1px solid #3333"},style:(`background-color: #3333; background-image: url('${_vm.getDocUrl(item.name)}')`)}):_c('iframe',{attrs:{"src":_vm.getDocUrl(item.name)}})]}},{key:"item.remove",fn:function({item}){return [_c('div',{key:_vm.refresh},[(!item.remove)?_c(VBtn,{attrs:{"x-small":"","depressed":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.isRemoveDoc(item, true)}}},[_vm._v(" УДАЛИТЬ ")]):_c('div',{staticStyle:{"display":"flex","align-items":"center","gap":"10px","padding":"5px 0"}},[_c('span',{staticClass:"caption",staticStyle:{"line-height":"2px"}},[_vm._v("Удалить?")]),_c(VBtn,{attrs:{"x-small":"","depressed":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.removeDoc(item)}}},[_vm._v(" ДА ")]),_c(VBtn,{attrs:{"x-small":"","depressed":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.isRemoveDoc(item, false)}}},[_vm._v(" НЕТ ")])],1)],1)]}}])})],1),_c(VDivider),_c(VCardActions,[_c('div'),_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeModal}},[_vm._v(" Закрыть ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }