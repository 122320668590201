<template>
  <div>
    <v-card-text>
      <v-data-table
          :headers="headers"
          :items="documents"
          :loading="loader"
          loading-text="Загрузка... Ожидайте!"
          style="cursor: pointer"
          :footer-props="{
            disableItemsPerPage: true,
            itemsPerPageText: ''
          }"
      >
        <template v-slot:item.name="{item}">
<!--          <img v-if="docType(item.name) !== 'PDF'" :src="getDocUrl(item.name)" :alt="item.name" width="200" height="100" />-->
          <div v-if="docType(item.name) !== 'PDF'" :style="`background-color: #3333; background-image: url('${getDocUrl(item.name)}')`" style="background-size: contain; background-repeat: no-repeat;
          background-position:
          center top; height: 200px; width: 300px; border: 1px solid #3333"></div>
          <iframe v-else :src="getDocUrl(item.name)" />
        </template>
        <template v-slot:item.remove="{item}">
          <div :key="refresh">
<!--            @click.stop="deleteReferenceImage(item.id, item.terms_reference)"-->
            <v-btn
                x-small
                depressed
                color="error"
                v-if="!item.remove"
                @click.stop="isRemoveDoc(item, true)"
            >
              УДАЛИТЬ
            </v-btn>
            <div v-else style="display: flex; align-items: center; gap: 10px; padding: 5px 0">
              <span class="caption" style="line-height: 2px">Удалить?</span>
              <v-btn
                  x-small
                  depressed
                  color="error"
                  @click.stop="removeDoc(item)"
              >
                ДА
              </v-btn>
              <v-btn
                  x-small
                  depressed
                  color="primary"
                  @click.stop="isRemoveDoc(item, false)"
              >
                НЕТ
              </v-btn>
            </div>
          </div>
        </template>
      </v-data-table>
    </v-card-text>

    <v-divider></v-divider>
    <v-card-actions>
      <div></div>
      <v-spacer></v-spacer>
      <v-btn
          color="blue darken-1"
          text
          @click="closeModal"
      >
        Закрыть
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import moment from "moment/moment";
import {nextTick} from "vue";

export default {
  name: 'product-session',
  props: {
    product: null,
  },
  data() {
    return {
      refresh: Date.now(),
      headers: [
        {
          text: 'Документ',
          align: 'start',
          filterable: true,
          value: 'name',
          class: "success--text subtitle-2 font-weight-bold"
        },
        {
          text: 'Действие',
          value: 'remove',
          remove: false,
        }
      ],
      documents: [],
      loader: true
    }
  },

  async created() {
    if (this.product) {
      this.documents = this.product.files
      this.loader = false
    }
  },
  destroyed() {
    this.sessions = []
  },
  methods: {
    async isRemoveDoc(item, status) {
      item.remove = status
      await nextTick()
      this.refresh = Date.now()
    },
    async removeDoc(item) {
      const res = await this.$store.dispatch('deleteFile', {name: item.name})
      console.log('RES', res)
      if (res.status === 200 && res.data === 'Файл удален!') {
        this.documents = this.documents.filter(itm => itm.name !== item.name)
        this.$emit('update-product')
      }
    },
    getDocUrl(image) {
      if (!image) return
      return window.location.hostname === 'localhost' ? `http://localhost:8888/images/files/${image}` :
          `https://server.tender-yaratelle.ru/images/files/${image}`
    },
    docType(doc) {
      if (!doc) return
      let typeArr = doc.split('.')
      if (typeArr.length > 1) {
        let typeRev = typeArr.reverse()
        if (typeRev[0] === 'pdf') return 'PDF'
        else if (typeRev[0] === 'png') return 'PNG'
        else if (typeRev[0] === 'jpg' || typeRev[0] === 'jpeg') return 'JPG'
        else return null
      }
    },
    closeModal() {
      this.$emit('close')
    }
  }
}
</script>
