import axios from 'axios'
import store from "@/store";

export default {
    namespace: true,
    state: () => ({
        modal: null
    }),
    mutations: {
        modalMutation(state, data) {
            state.modal = data || null
        }
    },
    actions: {
        modalAction({commit}, data) {
            commit('modalMutation', data)
        },
        getProducts() {
            return axios('/admin-all-products', {
                method: 'POST',
                headers: {
                    "Authorization": store.getters.getToken
                }
            })
        },
        updateProduct({}, payload) {
            return axios('/admin-update-product', {
                method: 'POST',
                headers: {
                    "Authorization": store.getters.getToken
                },
                data: payload
            })
        },
        sendSubscribers({}, payload) {
          return axios('/admin-send-subscribers', {
              method: 'POST',
              headers: {
                  "Authorization": store.getters.getToken
              },
              data: payload
          })
        },
        sendInvited({}, payload) {
            return axios('/admin-send-invited', {
                method: 'POST',
                headers: {
                    "Authorization": store.getters.getToken
                },
                data: payload
            })
        },
        deleteProduct({}, payload) {
            return axios('/admin-delete-product', {
                method: 'POST',
                headers: {
                    "Authorization": store.getters.getToken
                },
                data: payload
            })
        },
        uploadReferenceImage({}, formdata) {
            return axios('/admin-upload-terms', {
                method: 'POST',
                headers: {
                    "Authorization": store.getters.getToken,
                    'Content-Type': 'multipart/form-data'
                },
                data: formdata
            })
        },
        deleteReferenceImage({}, payload) {
            return axios('/admin-delete-terms', {
                method: 'POST',
                headers: {
                    "Authorization": store.getters.getToken
                },
                data: payload
            })
        },
        deleteFile({}, payload) {
            try {
                return axios('/admin-delete-file', {
                    method: 'POST',
                    headers: {
                        "Authorization": store.getters.getToken
                    },
                    data: payload
                })
            } catch (e) {
                console.error('ERROR IN deleteDoc', e)
            }
        },
        async getUserSession({}, payload) {
            const res =  await axios('/get-user-session',  {
                method: 'POST',
                headers: {
                    "Authorization": store.getters.getToken
                },
                data: payload
            })
            if (res.status === 200) return res.data
            else return []
        }
    },
    getters: {
        getProductsModal: state => state.modal
    }
}
