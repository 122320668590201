<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        scrollable
        max-width="1200"
    >
      <v-card :loading="loader">
        <v-card-title>
          <span class="text-h5" v-if="dialogData">{{ adminStatus ? 'Тендер:' : 'Изменение тендера:' }} <b>{{
              dialogData.product
            }}</b></span>
          <span class="text-h5" v-else>Нет данных для отображения...</span>
          <v-spacer></v-spacer>
          <small class="subtitle-2 font-weight-light">*Поля не могут быть пустыми</small>
        </v-card-title>

        <v-card-subtitle class="d-flex justify-space-between align-center pb-0">
          <v-chip
              v-if="dialogData"
              class="mt-1"
              color="rgb(228 228 228)"
          >
            № {{ dialogData.id }}
          </v-chip>

          <template>
            <v-btn-toggle
                v-model="menu"
                color="teal"
                group
                class="xs-buttons"
            >
              <v-btn value="data">
                Данные
              </v-btn>
              <v-btn value="session">
                Сессии
              </v-btn>
              <v-btn value="winners">
                Договора
              </v-btn>
              <v-btn value="documents">
                Документы
              </v-btn>
            </v-btn-toggle>
          </template>
        </v-card-subtitle>

        <template v-if="dialogData && menu==='data'">
          <v-divider></v-divider>
          <v-card-text class="mt-4">
            <v-container>
              <v-row>
                <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    class="pb-0"
                >
                  <v-text-field
                      :disabled="adminStatus || stopTrade(dialogData.stop_date)"
                      v-model="dialogData.product"
                      label="*Наименование тендера"
                      outlined
                      required
                      :error-messages="getErrors('product', $v.dialogData.product)"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    class="pb-0"
                >
                  <v-text-field
                      :disabled="adminStatus || stopTrade(dialogData.stop_date)"
                      v-model="dialogData.delivery_place"
                      label="*Адрес"
                      outlined
                      required
                      :error-messages="getErrors('delivery_place', $v.dialogData.delivery_place)"
                  ></v-text-field>
                </v-col>
              </v-row>

              <div v-if="dialogData && dialogData.multiproducts && dialogData.multiproducts.length &&
              !stopTrade(dialogData.stop_date)"
                   :data-render="multiRender">
                <v-row v-for="(multi, i) in dialogData.multiproducts" :key="i">
                  <v-col
                      cols="12"
                      sm="12"
                      md="5"
                  >
                    <v-text-field
                        v-model="multi.name"
                        label="*Наименование продукта"
                        outlined
                        required
                        :error-messages="multi.errorName ? 'Поле не может быть пустым!' : ''"
                        :placeholder="multiProductError ? 'Поле не может быть пустым!' : 'Наименование продукта'"
                        :disabled="+dialogData.moderation === 1 || stopTrade(dialogData.stop_date) || adminStatus"
                    ></v-text-field>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="12"
                      md="4"
                      style="display: flex; align-items: center"
                  >
                    <v-text-field
                        v-model="multi.volume"
                        label="*Количество"
                        outlined
                        required
                        @keyup="ev => multi.volume = ev.target.value.replace(/[^0-9]/g,'')"
                        :error-messages="multi.errorVolume ? 'Поле не может быть пустым!' : ''"
                        :placeholder="multiProductError ? 'Поле не может быть пустым!' : 'Количество'"
                        :disabled="+dialogData.moderation === 1 || stopTrade(dialogData.stop_date) || adminStatus"
                    ></v-text-field>
                    <v-select
                        v-model="multi.unit"
                        :items="getUnits.length ? getUnits : multiUnits"
                        outlined
                        required
                        style="max-width: 100px"
                        :disabled="+dialogData.moderation === 1 || stopTrade(dialogData.stop_date) || adminStatus"
                    ></v-select>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="12"
                      md="3"
                  >
                    <div style="display: flex; justify-content: center; align-items: center">

                      <v-text-field
                          v-model="multi.price"
                          label="Цена"
                          outlined
                          required
                          @keyup="ev => multi.price = ev.target.value.replace(/[^0-9]/g,'')"
                          :disabled="+dialogData.moderation === 1 || stopTrade(dialogData.stop_date) || adminStatus"
                      >
                      </v-text-field>


                      <div
                          style="display: flex; justify-content: center; align-items: center; padding-bottom: 30px; font-size: 20px"
                          v-if="+dialogData.moderation !== 1 || !stopTrade(dialogData.stop_date) || adminStatus"
                      >
                        <v-btn
                            class="mx-2"
                            fab
                            dark
                            x-small
                            color="primary"
                            v-if="dialogData.multiproducts.length === i+1"
                            @click="addMultiproduct"
                            :disabled="+dialogData.moderation === 1 || stopTrade(dialogData.stop_date) || adminStatus"
                        >
                          <v-icon>
                            mdi-plus
                          </v-icon>
                        </v-btn>
                        <v-btn
                            class="mx-2"
                            fab
                            dark
                            x-small
                            color="#f54e4e"
                            v-if="i+1 > 1"
                            @click="removeMultiproduct(i, multi.id)"
                            :disabled="+dialogData.moderation === 1 || stopTrade(dialogData.stop_date) || adminStatus"
                        >
                          <v-icon>
                            mdi-minus
                          </v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>


              <v-row>
                <v-col
                    cols="12"
                    sm="12"
                    :md="!dialogData.multiproducts.length ? 4 : 6"
                    class="pb-0"
                >
                  <v-select
                      v-model="dialogData.type"
                      :items="[
                        {type:'offer', name:'Аукцион'},
                        {type:'market', name:'Тендер'},
                        {type:'request-market', name:'Запрос предложений'},
                        {type:'request-offer', name:'Запрос котировок'}
                      ]"
                      item-text="name"
                      item-value="type"
                      label="Тип торгов"
                      outlined
                      :disabled="dialogData.moderation === '1' || adminStatus || stopTrade(dialogData.stop_date)"
                  ></v-select>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    :md="!dialogData.multiproducts.length ? 4 : 6"
                    class="pb-0"
                    v-if="real_start_date"
                >
                  <v-select
                      v-model="dialogData.moderation"
                      :disabled="+dialogData.moderation === 1 && (real_start_date < Math.ceil(Date.now() / 1000)) ||
                      stopTrade(dialogData.stop_date) || adminStatus"
                      :items="[
                        {type:'0', name:'На модерации'},
                        {type:'1', name:'Снят с модерации'}
                      ]"
                      item-text="name"
                      item-value="type"
                      label="Модерация продукта"
                      outlined
                  ></v-select>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="2"
                    class="pb-0"
                    v-if="!dialogData.multiproducts.length && !stopTrade(dialogData.stop_date)"
                >
                  <v-text-field
                      v-model="dialogData.start_price"
                      label="*Стартовая цена"
                      outlined
                      required
                      :error-messages="getErrors('start_price', $v.dialogData.start_price) || adminStatus"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="2"
                    class="pb-0"
                    v-if="!dialogData.multiproducts.length && !stopTrade(dialogData.stop_date)"
                >
                  <v-text-field
                      v-model="dialogData.volume"
                      label="*Количество"
                      outlined
                      required
                      :error-messages="getErrors('volume', $v.dialogData.volume) || adminStatus"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    class="pb-7"
                >
                  <VueCtkDateTimePicker
                      v-model="startDate"
                      id="VueCtkDateTimePicker-1"
                      :no-leabel="true"
                      :header="false"
                      locale="ru"
                      label="Начало торгов по МСК"
                      format="YYYY-MM-DD HH:mm:ss"
                      formatted="LLL"
                      time-zone="Europe/Moscow"
                      :no-button-now="true"
                      :noClearButton="true"
                      :disabled="adminStatus || stopTrade(dialogData.stop_date)"
                      :hint="getErrors('start_date', $v.dialogData.start_date)[0]"
                      :error="!!getErrors('start_date', $v.dialogData.start_date)[0]"
                  />
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    class="pb-7"
                >
                  <VueCtkDateTimePicker
                      v-model="stopDate"
                      id="VueCtkDateTimePicker-2"
                      :no-leabel="true"
                      :header="false"
                      locale="ru"
                      label="Окончание торгов по МСК"
                      format="YYYY-MM-DD HH:mm:ss"
                      formatted="LLL"
                      time-zone="Europe/Moscow"
                      :no-button-now="true"
                      :noClearButton="true"
                      :disabled="adminStatus || stopTrade(dialogData.stop_date)"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                    cols="12"
                    sm="12"
                    md="4"
                    class="pb-0"
                >
                  <v-select
                      v-model="dialogData.payment_term"
                      :items="[
                        {payment_term:'Нет', name:'Нет'},
                        {payment_term:'Предоплата', name:'Предоплата'},
                        {payment_term:'Отсрочка платежа', name:'Отсрочка платежа'}
                      ]"
                      item-text="name"
                      item-value="type"
                      label="Условие оплаты"
                      outlined
                      :disabled="adminStatus || stopTrade(dialogData.stop_date)"
                  ></v-select>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="3"
                    class="pb-0"
                >
                  <v-text-field
                      v-model="dialogData.payment_percent"
                      :label="dialogData.payment_term === 'Нет' ? '% | Нет условия' : '%'"
                      outlined
                      :disabled="dialogData.payment_term === 'Нет' || dialogData.payment_term === 'Отсрочка платежа'
                      || adminStatus || stopTrade(dialogData.stop_date)"
                      :error-messages="getErrors('payment_percent', $v.dialogData.payment_percent)"
                      required
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="5"
                    class="pb-0"
                >
                  <v-text-field
                      v-model="dialogData.balance_percent"
                      :label="dialogData.payment_term === 'Отсрочка платежа' && dialogData.payment_percent ? 'Оплатить в течение (дней)' : dialogData.payment_term === 'Предоплата' && dialogData.payment_percent ? `Оставшиеся ${100 - dialogData.payment_percent} в течение (дней)` : 'Период | Не указан процент'"
                      outlined
                      :disabled="!dialogData.payment_percent || adminStatus || stopTrade(dialogData.stop_date)"
                      :error-messages="getErrors('balance_percent', $v.dialogData.balance_percent)"
                      required
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    class="pb-0"
                >
                  <v-textarea
                      v-model="dialogData.text"
                      label="Комментарии"
                      outlined
                      required
                      :disabled="adminStatus || stopTrade(dialogData.stop_date)"
                  ></v-textarea>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    class="pb-0"
                >
                  <v-textarea
                      v-model="dialogData.user_partner"
                      label="Приглашенные (рассылка)"
                      outlined
                      :error-messages="getErrors('user_partner', $v.dialogData.user_partner)"
                      required
                      :disabled="!!(+dialogData.moderation) || adminStatus || stopTrade(dialogData.stop_date)"
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    class="pb-0 pt-0"
                >
                  <v-switch
                      v-model="dialogData.mailing_api"
                      color="success"
                      inset
                      :label="`Рассылка по внешним площадкам: ${dialogData.mailing_api ? 'Вкл.' : 'Выкл.'}`"
                      :disabled="!!(+dialogData.moderation) || adminStatus || stopTrade(dialogData.stop_date)"
                  ></v-switch>
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    class="pb-0 pt-0"
                >
                  <v-switch
                      v-model="dialogData.mailing_dep"
                      color="success"
                      inset
                      :label="`Рассылка подписанным поставщикам: ${dialogData.mailing_dep ? 'Вкл.' : 'Выкл.'}`"
                      :disabled="!!(+dialogData.moderation) || adminStatus || stopTrade(dialogData.stop_date)"
                  ></v-switch>
                </v-col>
              </v-row>

            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <div class="text-center" v-if="!adminStatus && !stopTrade(dialogData.stop_date)">
              <v-menu
                  top
                  close-on-click
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="error"
                      dark
                      text
                      v-bind="attrs"
                      v-on="on"
                      :disabled="loader"
                  >
                    УДАЛИТЬ
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item>
                    <v-btn
                        color="success"
                        text
                    >
                      Отменить
                    </v-btn>
                    <v-btn
                        color="error"
                        text
                        @click="deleteProduct"
                    >
                      Удалить
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>

            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="closeUpdate"
            >
              Закрыть
            </v-btn>
            <v-btn
                v-if="!adminStatus && !stopTrade(dialogData.stop_date)"
                color="blue darken-1"
                text
                @click="updateProduct"
                :disabled="loader"
            >
              Сохранить
            </v-btn>
          </v-card-actions>
        </template>

        <template v-if="menu==='session'">
          <session :id="dialogData.id" @close="closeModal"/>
        </template>

        <template v-if="menu==='winners'">
          <winners :product="dialogData" @close="closeModal"/>
        </template>

        <template v-if="menu==='documents'">
          <documents :product="dialogData" @close="closeModal" @update-product="updateProducts"/>
        </template>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {required, numeric, requiredIf} from "vuelidate/lib/validators"
import moment from "moment";
import session from "@/components/dialogs/product/session";
import winners from "@/components/dialogs/product/winners";
import documents from "@/components/dialogs/product/documents.vue";

moment.locale('ru');
import tz from "moment-timezone"

moment.tz('Europe/Moscow');
export default {
  name: "product",
  components: {
    session,
    winners,
    documents
  },
  data: () => ({
    dialog: false,
    dialogData: null,
    menu1: false,
    menu2: false,
    real_start_date: null,
    product_id: null,
    startDate: null,
    stopDate: null,
    baseStartDate: null,
    baseStopDate: null,
    loader: false,
    textareaError: [],
    textareaHTML: '',
    multiProductError: true,
    multiRender: Date.now() / 1000,
    multiUnits: ['шт.', 'тонн', 'кг', 'м2', 'м3', 'пог. метр', 'л.'],
    removeMultiproductsID: [],
    menu: 'data'
  }),
  validations: {
    dialogData: {
      product: {required},
      start_price: {
        oldStartPrice(val) {
          return !this.dialogData.multiproducts.length && !val ? false : true
        }
      },
      delivery_place: {required},
      volume: {
        oldVolume(val) {
          return !(!this.dialogData.multiproducts.length && !val)
        }
      },
      start_date: {
        incorrectDate() {
          const currentDate = moment();
          const databaseDate = moment(this.startDate);
          return !currentDate.isAfter(databaseDate)
        },
        startAfterStop() {
          const start = moment(this.startDate);
          const stop = moment(this.stopDate);
          return !start.isAfter(stop)
        }
      },
      payment_percent: {
        required: requiredIf(function (value) {
          if (value.payment_term !== 'Нет') return true
        }),
        numeric,
        maxPercent: (value, data) => {
          if (data.payment_term === 'Нет') return true
          else if (parseInt(value) < 101) return true
          else return false
        }
      },
      balance_percent: {
        required: requiredIf(function (value) {
          if (value.payment_term !== 'Нет') return true
        }),
        numeric
      },
      user_partner: {
        textEmail(val) {
          const Regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          if (val) {
            let error = []
            let newEmailArr = []
            let emailArr = val.split('\n')
            if (emailArr.length) {
              for (let item of emailArr) {
                if (item) {
                  if (!item.match(Regex)) {
                    error.push(item)
                    newEmailArr.push(item)
                  }
                }
              }
              let newInnerHTML = newEmailArr.join(' | ')
              if (newInnerHTML) this.textareaHTML = 'Не валидны email: ' + newInnerHTML
              if (error.length) {
                this.textareaError = error
                return false
              } else return true
            } else return true
          } else return true
        }
      }
    }
  },
  created() {
    this.$v.$reset()
  },
  beforeDestroy() {
    this.$v.$reset()
  },
  computed: {
    modalStatus() {
      return this.$store.getters.getProductsModal
    },
    getUnits() {
      const Units = this.$store.getters.getUnits
      if (Units.length) {
        return Units.map(item => item.title)
      } else return []
    },
    adminStatus() {
      return this.$store.getters.getAdminRole !== 'Администратор'
    }
  },
  watch: {
    modalStatus(val) {
      if (val) {
        this.real_start_date = val.start_date
        this.dialog = true
        this.dialogData = val
        if (this.dialogData) {
          this.dialogData.mailing_api = +this.dialogData.mailing_api
          this.dialogData.mailing_dep = +this.dialogData.mailing_dep
          if (this.dialogData.multiproducts && this.dialogData.multiproducts.length) this.dialogData.multiproducts =
              this.dialogData.multiproducts.map(itm => {
                if (!+itm.price) itm.price = ''
                return itm
              })
        }
        this.startDate = this.baseStartDate = moment.unix(val.start_date).format("YYYY-MM-DD HH:mm:ss")
        this.stopDate = this.baseStopDate = moment.unix(val.stop_date).format("YYYY-MM-DD HH:mm:ss")
      }
    },
    dialog(val) {
      if (!val) {
        this.$store.dispatch('modalAction', null)
        this.dialogData = null
      }
    },
    "dialogData.payment_term"(val) {
      if (val === "Нет") this.dialogData.payment_percent = this.dialogData.balance_percent = ''
      else if (val === "Отсрочка платежа") this.dialogData.payment_percent = 100
    }
  },
  methods: {
    stopTrade(val) {
      if (!val) return
      if (moment.unix(val).valueOf() < moment().valueOf()) return true
      else return false
    },
    getErrors(name, model) {
      const errors = [];
      if (!model.$dirty) return errors

      switch (name) {
        case "product":
          !model.required ? errors.push("Поле не может быт пустым!") : ""
          break;
        case "start_price":
          !model.oldStartPrice ? errors.push("Поле не может быт пустым!") : ""
          break;
        case "start_date":
          !model.incorrectDate ? errors.push("Начало торгов не может быть меньше текущего времени!") :
              !model.startAfterStop ? errors.push("Время старта торгов не может быть больше чем завершение торгов!") : ""
          break;
        case "delivery_place":
          !model.required ? errors.push("Поле не может быт пустым!") : ""
          break;
        case "volume":
          !model.oldVolume ? errors.push("Поле не может быт пустым!") : ""
          break;
        case "payment_percent":
          !model.required ? errors.push("Поле не может быт пустым!") :
              !model.numeric ? errors.push("Допускается заполнение только целыми числами!") :
                  !model.maxPercent ? errors.push("максимальное значение 100") : ""
          break;
        case "balance_percent":
          !model.required ? errors.push("Поле не может быт пустым!") :
              !model.numeric ? errors.push("Допускается заполнение только целыми числами!") : ""
          break;
        case "user_partner":
          !model.textEmail ? errors.push(this.textareaHTML) : ""
          break;
        default:
          break;
      }
      return errors;
    },
    async closeUpdate() {
      this.$store.commit('modalMutation', null)
      this.dialog = false
    },
    closeModal() {
      this.menu = 'data'
      this.closeUpdate()
    },
    async updateProduct() {
      if (this.$v.dialogData.$invalid) {
        this.$v.$touch();
        return;
      }

      if (!await this.multiProductValidation()) return

      if ((moment(this.startDate).format('YYYY-MM-DD HH:mm') !== moment(this.baseStartDate).format('YYYY-MM-DD HH:mm')) || (moment(this.stopDate).format('YYYY-MM-DD HH:mm') !== moment(this.baseStopDate).format('YYYY-MM-DD HH:mm'))) {
        let confirmQuestion = confirm('Время изменено! Сохранить изменения?')
        if (!confirmQuestion) return
      }

      this.dialogData.start_date = moment(this.startDate).format('X')
      this.dialogData.stop_date = moment(this.stopDate).format('X')

      let {
        id,
        product,
        delivery_place,
        type,
        moderation,
        multiproducts,
        start_price,
        volume,
        start_date,
        stop_date,
        payment_term,
        payment_percent,
        balance_percent,
        text,
        user_partner,
        mailing_api,
        mailing_dep
      } = this.dialogData

      let payload = {
        id: parseInt(id),
        department_id: this.dialogData.user.department_id,
        product,
        delivery_place,
        type,
        moderation: parseInt(moderation),
        multiproducts,
        start_price: this.dialogData.multiproducts && this.dialogData.multiproducts.length && !start_price ? 1 : start_price,
        volume: this.dialogData.multiproducts && this.dialogData.multiproducts.length && !volume ? 1 : volume,
        start_date,
        stop_date,
        payment_term,
        payment_percent: parseInt(payment_percent) || '',
        balance_percent: parseInt(balance_percent) || '',
        text,
        user_partner,
        mailing_api: mailing_api ? 1 : 0,
        mailing_dep: mailing_dep ? 1 : 0,
        remove_multiproducts_id: this.removeMultiproductsID
      }
      this.loader = true
      let resUpdate = await this.$store.dispatch('updateProduct', payload)
      if (resUpdate.status === 200) {
        const {id, moderation_new, moderation_old, mailing_dep, user_partner} = resUpdate.data
        this.removeMultiproductsID = []
        await this.closeUpdate()
        this.loader = false
        // mail sending
        if (moderation_new === 1 && moderation_old === 0) {
          // если подключена рассылка по департаментам
          if (mailing_dep) {
            const ctx = this
            let payloadS = {
              id,
              department_id: payload.department_id,
              user_id: 0
            }
            await ctx.$emit('subscribers', {message: 'start'})

            async function getSubscribers() {
              let resSendSubscribers = await ctx.$store.dispatch('sendSubscribers', payloadS)
              if (resSendSubscribers.status === 200) {
                await ctx.$emit('subscribers', resSendSubscribers.data)
                // if (resSendSubscribers.data.user_id) {
                //   payloadS.user_id = resSendSubscribers.data.user_id
                //   getSubscribers()
                // }
              }
            }

            this.$nextTick(()=>{
              getSubscribers()
            })

          }
          if (user_partner) {
            const ctx = this
            let payloadI = {
              id
            }
            await ctx.$emit('invited', {message: 'start'})

            async function getInvited() {
              let resSendInvited = await ctx.$store.dispatch('sendInvited', payloadI)
              if (resSendInvited.status === 200) {
                await ctx.$emit('invited', resSendInvited.data)
                if (resSendInvited.data.email) {
                  let Partners = user_partner.split('\n')
                  const Index = Partners.findIndex(email => email === resSendInvited.data.email)
                  payloadI.index = Index
                  payloadI.error = 'error'
                  getInvited()
                }
              }
            }

            getInvited()
          }
        }
      } else {
        alert('[ОШИБКА] Обратитесь в службу технической поддержки!')
      }
      this.loader = false
    },
    async deleteProduct() {
      let payload = {
        product_id: +this.dialogData.id,
      }
      let res = await this.$store.dispatch('deleteProduct', payload)
      if (res && res.status === 200) {
        await this.closeUpdate()
        await this.$store.dispatch('getProducts')
        this.$emit('data')
      } else console.error('ERROR IN deleteOperator')
    },
    updateProducts() {
      this.$emit('data')
    },
    addMultiproduct() {
      this.dialogData.multiproducts.push({
        name: '',
        open: 0,
        price: '',
        product_id: this.dialogData.id,
        unit: this.getUnits.length ? this.getUnits[0] : this.multiUnits[0],
        volume: ''
      })
    },
    removeMultiproduct(i, id = null) {
      if (id) this.removeMultiproductsID.push(id)
      this.dialogData.multiproducts = this.dialogData.multiproducts.filter((item, idx) => idx !== i)
    },
    async multiProductValidation() {
      const errorArr = []
      for (let item of this.dialogData.multiproducts) {
        if (!item.name) {
          item.errorName = true
          errorArr.push('error')
        } else item.errorName = false

        if (!item.volume) {
          item.errorVolume = true
          errorArr.push('error')
        } else item.errorVolume = false
      }
      if (errorArr.length) {
        this.multiRender = Date.now() / 1000
        this.multiProductError = true
        return false
      } else {
        this.multiRender = Date.now() / 1000
        this.multiProductError = false
        return true
      }
    },
  }
}
</script>

<style scoped>
>>> .v-dialog {
  overflow-y: visible;
}

@media (max-width: 812px) {
  >>> .v-dialog {
    overflow-y: auto;
  }
}
</style>


